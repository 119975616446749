import { createGlobalStyle } from 'styled-components'
import b from './Breakpoints'

import Normal from '../assets/fonts/HKGroteskPro-Regular.woff2'
import SemiBold from '../assets/fonts/HKGroteskPro-SemiBold.woff2'
import Compression from '../assets/fonts/HKCompression-HeavyCondensed.woff'

export default createGlobalStyle`

    @font-face {
        font-family: 'HK Grotesk Pro';
        src: url(${Normal});
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'HK Grotesk Pro';
        src: url(${SemiBold});
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'HK Compression';
        src: url(${Compression});
        font-weight: normal;
        font-style: normal;
    }

    h1{
        font-size: 2.8rem;
        font-weight: bold;

        @media ${b.small}{
            font-size: 3.2rem;
        }

        @media ${b.large}{
            font-size: 5.2rem;
        }
    }

    h2{
        font-size: 2.4rem;
        font-weight: bold;

        @media ${b.small}{
            font-size: 3.6rem;
        }
    }

    h3{
        font-size: 3rem;
    }

`