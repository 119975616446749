import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'

export default (props) => {
    const { children } = props

    return (
        <div {...props}>
            <GlobalStyles />
            <Typography />
            {children}
        </div>
    )
}