import { createGlobalStyle } from 'styled-components'
import b from './Breakpoints'

export default createGlobalStyle`

    html {
        --font-family: 'HK Grotesk Pro';
        --font: 1.6rem;
        --font--x-small: 1.4rem;
        --font--small: 1.4rem;
        --font--large: 1.8rem;

        --b--radius: 0.2rem;
        --container: 120rem;
        --container--small: 70rem;
        --form: 50rem;

        --background: #000000;
        --accent: #D0F224;
        --accent--tint: #b5d60d;
        --dark: #101010;

        --spacer: 5rem;
        --spacer--xl: 5rem;

        --gutter: 5rem;
        --gutter--xl: 12rem;

        @media ${b.large}{
            --gutter: var(--gutter--xl);
            --spacer--xl: 7.5rem;
        }

        @media ${b.desktop}{
            --container: 152rem;
        }

        margin: 0;
        font-size: 10px;
        line-height: 1.5;
        box-sizing: border-box;
        background: var(--background);
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body {
        font-size: 1.6rem;
        font-family: var(--font-family);
        line-height: inherit;
        color: white;
    }

    em, i{
        font-style: italic;
    }

    strong, b {
        font-weight: 500;
    }

    button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;

        background: transparent;

        color: inherit;
        font: inherit;

        line-height: normal;

        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        outline: none;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
`